import React from 'react'
import Loader from '../ui/atoms/loader/loader'
import FormContainer from '../components/FormContainer/FormContainer'
import LoaderContainer from '../components/LoaderContainer'

export default function Home() {
  return (
    <FormContainer>
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    </FormContainer>
  )
}
